

$c-bg: #F8EFE6;
$c-bg-light:#fbf8f5; //light-> fefcfa  moyen-> fbf8f5
$c-bg-menu: #F2DFCB;
$c-red: #8A2C1F;
$c-text-alt: #5F4630;

$c-alt: #986F4A;
$c-cta: #D89436;
$c-cta-alt: #C88B6B;


$f-text: quasimoda, sans-serif;
$f-title: quiche-text, sans-serif;
$line-height: 1.5;

$size-text-1: 14px;
$size-text0: 16px;
$size-text1: 18px;
$levelh3: 21px;
$levelh2: 25px;
$levelh1: 28px;

/*$size-text-1-m: 14px;
$size-text0-m: 17px; 
$levelh3-m: 21px;
$levelh2-m: 27px;
$levelh1-m: 33px;*/

$size-text-1-lg: 14px;
$size-text0-lg: 18px;
$size-text1-lg: 20px; 
$levelh3-lg: 23px;
$levelh2-lg: 28px;
$levelh1-lg: 35px;

$screen-s: 500px;
$screen-sm: 600px;
$screen-md: 800px; //text
$screen-lg: 1000px;
$screen-xl: 1200px;

