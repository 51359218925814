@mixin s{
    @media (min-width:#{$screen-s}) {
        @content;
    }
}
@mixin sm{
    @media (min-width:#{$screen-sm}) {
        @content;
    }
}
@mixin md{
    @media (min-width:#{$screen-md}) {
        @content;
    }
}
@mixin lg{
    @media (min-width:#{$screen-lg}) {
        @content;
    }
}
@mixin xl{
    @media (min-width:#{$screen-xl}) {
        @content;
    }
}
@mixin retina{
    @media (-webkit-min-device-pixel-ratio: 2), 
       (min-resolution: 192dpi) {
        @content;
    }
}
