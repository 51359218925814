.menu{
    @include sm{
        margin: 0;
        padding: 0;
        }
    @include lg{
        margin: 0 auto;
        max-width: 800px;
    }

    &__div{
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: $c-bg-menu;
        color: $c-text-alt;

        &--closed{
            display: none;
        }

        @include sm{
            display: block;
            background-color: transparent;
            position: relative;
        }
    }
    &__btn{
        padding: 0;
        position: absolute;
        top: 6px;
        right: 6px;
        height: 34px;
        width: 34px;
        background: no-repeat;
        background-color: $c-bg;
        border:none;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 100%;
        cursor: pointer;
        /*&--projets{

        }*/
        &--open{
            background: url(../assets/images/bgmenu.svg);
        }
        &--close{
            background: url(../assets/images/closemenu.svg);
            background-color: $c-bg-menu;
            &--accueil{
                margin: 0px 40px 0px 0px;
            }
        }
        @include sm{
            display: none;
        }
    }
    &__ul{
        margin: 96px 48px;
        list-style: none;
        padding: 0;

        @include sm{
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
        }

        &--el{
            margin: 48px 0;

            @include sm{
                border-bottom: solid 2px $c-cta-alt;
                margin: 0px;

                &:hover{
                    cursor: pointer;
                    border-bottom: solid 2px $c-red;
                }
            }  

            &--1, &--b{
                @include sm{
                    display: none;
                }
            }
         
        }
    }
}
.contact{
    &__ul{
        list-style: none;
        padding: 0;
        //margin-top: 48px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        @include sm{
            justify-content: flex-start;
            gap: 48px;
        }     
    }

    &__li a{

        display: inline-block;
        width: 48px;
        height: 48px;
        background-image: url(../assets/images/mail.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 48px 48px;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;

        /*@include sm{
            margin-right: 48px;
        }*/

        &:hover{
            transform: scale(1.1);
            background-image: url(../assets/images/mail-hov.svg);
        }
    }

    &__li--cv a{
        background-image: url(../assets/images/cv.svg);
        &:hover{
            background-image: url(../assets/images/cv-hov.svg);
        }
    }

    &__li--linkedin a{
        background-image: url(../assets/images/linkedin.svg);
        &:hover{
            background-image: url(../assets/images/linkedin-hov.svg);
        }
         
    }

    &__li--insta a{
        background-image: url(../assets/images/instagram.svg);
        &:hover{
            background-image: url(../assets/images/instagram-hov.svg);
        }
    }


}
.projets{
    &__ul{
        list-style: none;
        padding: 0;
        margin: 0;

        @include sm{
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            //justify-content: space-evenly;
            align-items: center;
        }
        &--2{
            @include sm{
                margin-top: 48px;
            }
        }
    }
    &__li{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        //max-width: 192px;
        &__hidde{
            display: none;
        }
        @include sm{
            align-items: center;
            margin: 0;
        }
        /*&--text{
            border-bottom: solid 2px $c-cta;
            &:hover{
                border-bottom: solid 2px $c-text;
            }
        }*/
        &--img{
            //border-radius: 50%;
            //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
            width: 192px;
            height: 192px;
            margin-top: 48px;
            margin-bottom: 8px;
            @include sm {
                width: 164px;
                height: 164px;
                margin-top: 0px;
                margin-bottom: 24px;
            }
            @include md {
                width: 200px;
                height: 200px;
                margin-top: 0px;
                margin-bottom: 24px;
            }
            @include lg {
                width: 216px;
                height: 216px;
            }             
        }

    }

}
.images{
    &__ul{
    list-style: none;
    padding: 0;
    margin: 0;
        @include sm{
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            justify-content: space-evenly;
            align-items: center;
        }
    }
    &__li{
    }
}
.list{
    padding-inline-start: 0px;
    &__el{
        padding-bottom: 10px;
        &--end{
            padding-bottom: 0px;
        }
    }

}
.hidde{
    display: none;
}