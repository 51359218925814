.body{
    background-color: $c-bg-light;
}

p{
    margin: 24px 0px;

    /*@include lg{
        margin: 27px 0px;
    }*/
}

.title{
    margin: 24px 0px;

    @include sm{
        //margin: 50px 0px;
    }
    @include lg{
        //margin: 54px 0px;
    }
    &__2{
        text-align: left;
        //margin: 24px 0px 34px 12px;
        position: relative;

        &::before{
            //display: block;
            position: absolute;
            top: -8px;
            left: -14px;
            height: 26px;
            width: 26px;
            content: "";
            background: url(../assets/images/portfolio_eclat_br.svg);
            background-size: 26px 26px;
            background-repeat: no-repeat;
        }
    }
}

.btn{
    background-color: $c-bg-light;
    border: solid 2px $c-red;
    border-radius: 20px;
    color: $c-red;
    font-weight: 800;
    font-variant: small-caps;
    letter-spacing: 0.02em;
    padding: 7px 12px 5px;
    text-align: center;
    //margin: 47px 0px 0px;
    cursor: pointer;

    &:hover{
        @include sm{
            border: solid 2px $c-text-alt;
            color: $c-text-alt;
        }
    }
}


//Sections

.header{
  height: 48px;
  background-color: $c-bg;
  &--light{
    background-color: $c-bg-light;
  }

  @include sm{
    position: relative;
    padding: 48px 48px 0 48px;
    height: 72px;
  }
  @include md{
    //max-width: 800px;
    padding: 48px auto 0px;
  }
  /*@include lg{
    height: 81px;
    padding: 54px auto 0px;
  }*/
}

.section{
    padding: 24px;
    background-size: 300%;

    @include s{
        background-size: 200%;
    } 
    @include md{
        background-size: 150%;
        padding: 48px;
    }
    @include lg{
        background-size: 100%;
        //padding: 48px;
    }
/*page accueil*/
    &__intro{
        //height: 70vh;
        background-color: $c-bg;
        background-image: url(../assets/images/portfolio-lignes2.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        //padding: 20vh 24px 40vh;
        padding: 30vh 24px 30vh;
        
        @include md {
            height: 60vh;
            //padding: 10vh 48px 10vh;
            padding: 20vh 48px 10vh;
        }

        /*@include lg{
            height: 60vh;
            padding: 10vh 48px 10vh;
        }*/
    }
    &__projets{
        //padding: 120px 24px;
        //background-image: url(../assets/images/portfolio-lignes3.svg);
        //background-position: 30% bottom;
        //background-repeat: no-repeat;
        //padding-bottom: 280px;
        padding-bottom: 80px;
        @include md{
            padding: 48px 48px;
        }/*
        @include lg{
            margin-top: 135px;
            margin-bottom: 135px;
        }
        @include xl{
            margin-top: 135px;
            margin-bottom: 135px;
        }

        &--presentation{
            @include sm{
                display: flex;
                align-items: flex-end;
            }
        }*/
    }
    &__contact{
        //background-image: url(../assets/images/portfolio-lignes2.svg);
        //background-position: 20% bottom;
        //background-repeat: no-repeat;
        //padding-bottom: 280px;
        //background-color: $c-bg;
    }
/*page projet seul*/
    &__abstract{
        
    }
    &__skills{
        
    }
    &__images{
        &--img{
            max-width: 100%;
            &--2{
                @include sm{
                    gap: 20px;
                }
            }
        }
        &--videowrapper{
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
        }
        &--video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }        
        
    }  
}

.footer{
    margin: 120px 0px 24px;

    @include sm{
        margin: 100px 0px 50px;
    }
    
    @include lg{
        margin: 108px 0px 54px;
    }
}
//div
.content{
    //margin: 80px 24px;

    /*@include sm{
        margin: 100px 50px;
    }
    @include md{
        max-width: 800px;
        margin: 100px auto;
    }*/
    @include lg{
        max-width: 800px;
        margin: auto;
    }

    &__intro{
        //margin: 40% 24px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*@include sm{
            margin: 100px 50px;
        }
        @include md{
            max-width: 800px;
            margin: 100px auto;
        }
        @include lg{
            margin: 135px auto;
        }*/
        &--title{
            position: relative;
            //display: inline;
            text-align: center;

            &::before{
                position: absolute;
                top: -5px;
                right: -22px;
                height: 37px;
                width: 44px;
                content: "";
                background: url(../assets/images/portfolio_eclat.svg);
                background-size: 44px 37px;
                background-repeat: no-repeat;
            }
        }
        &--subtitle{
            position: relative;

            &:after {
                display: block;
                position: absolute;
                top: 18px;
                height: 16px;
                width: 110px;
                content: "";
                background: url(../assets/images/portfolio_souligner.svg);
                background-size: 110px 16px;
                background-repeat: no-repeat;
            }
        }  
    }

    &__projets{
        text-align: center;
    }
}

.projet{
    /*&__text{

    }*/
    &__img{
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 rgba(92, 67, 33, 0.2), 0 2px 4px 0 rgba(92, 67, 33, 0.19);
        width: 192px;
        height: 192px;
        margin-top: 24px;
        @include sm {
            margin-top: 0px;
            margin-bottom: 25px;
            margin-left: 50px;
            width: 200px;
            height: 200px;
        }
        @include md {
            width: 225px;
            height: 225px;
            margin-left: 75px;
        }
        @include lg {
            width: 243px;
            height: 243px;
            margin-left: 81px;
            margin-bottom: 27px;
        }      
    }
}
.hidde{
    display: none;
}