.body {
  font-family: $f-text;
  font-size: $size-text0;
  line-height: $line-height;
  color: $c-red;

  @include md{
    font-size: $size-text0-lg;
  }
}

.title {
  font-family: $f-title;
  font-size: $levelh1;
  font-weight: 500;
  
  @include md{
    font-size: $levelh1-lg;
  }

  &__2{
    font-size: $levelh3;

    @include md{
      font-size: $levelh3-lg;
    }
  }
}

.projets__li{
  &--text{
    font-size: $size-text1;
    font-weight: 500;
  }
  &--subtitle{
    color: $c-text-alt;
    font-variant: small-caps;
    font-weight: 600;
    font-size: $size-text-1;
    //letter-spacing: 0.02em;
    margin: 0;
  }
}

.menu__ul--el{
  font-size: $size-text1;  

  @include md{
    font-size: $size-text1-lg;
   }
}

.link{
  text-decoration: none;
  background-color: $c-bg-menu;
  color: $c-text-alt;
  padding: 2px 1px;
  font-weight: bold;

  &:hover, a:focus{
      text-decoration: underline;
  }
}

a{
  text-decoration: none;
  color: $c-red;
}

.btn{
  font-size: $size-text0;

  @include md{
    font-size: $size-text0-lg;
   }
}

.footer{
  &__text{
    text-align: center;
    color: $c-alt;
    font-family: $f-text;
    font-size: $size-text-1;

    @include md{
      font-size: $size-text-1-lg;
    }
  }
    
  &__link{
    color: $c-alt;
    &:hover{
      text-decoration: underline;
    }
  }
}


.nowrap{
  white-space: nowrap;
}
