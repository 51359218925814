@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.menu{
    &__div{
        animation: fadein 0.3 ease-in;
    }
}
